import React from 'react';
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout';
import { Grid } from '@material-ui/core';
import FeatureProduct from '../components/FeaturedProduct';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from '../theme/theme';
import Features from '../components/features';
import './Masonry.css';
import MapBox from "../assets/map_box.png"
import ContactUs from "../components/Contactus"
import MapImage from "../assets/map.png"


const Products = ({ data }) => {
    const classes = useStyles();

    // const title_max_length = 20;
    const theme = useTheme()
    const rows = [...Array(Math.ceil(data.allWpProduct.edges.length / 4))];
    const productRows = rows.map((row, idx) => data.allWpProduct.edges.slice(idx * 7, idx * 7 + 7))
    const isSmall = useMediaQuery(theme.breakpoints.down("md"))
    const isXtraSmall = useMediaQuery(theme.breakpoints.down("sm"))
    const isMed = useMediaQuery(theme.breakpoints.down(1280))
    const content = productRows.map((row, idx) => (
        <div container className="grid" key={idx}>
            {row.map(product => <article key={product.node.id} className="column"><img className="product-image" src={product.node?.image.sourceUrl} />
                <div className="item-hover">
                    <div className="product">
                        <p className="product-title">{product.node?.name}</p>
                        {/* {product.node?.productCustomField?.scientificName ? <p className="science">Scientific Name <br/><span>{`${product.node?.productCustomField?.scientificName.substring(0, title_max_length)}`}</span></p> : null} */}
                        <Link to={`/product/${product.node?.slug}`}><button className="view-product">Learn More</button></Link>
                    </div>

                </div>
            </article>)}
        </div>)
    )
    console.log(content)


    const isTab = useMediaQuery(theme.breakpoints.down(1100));
    const isXs = useMediaQuery(theme.breakpoints.down(400));
    return (
        <Layout>

            <Grid container className={classes.productBanner}>
                <Grid xs={12} className={classes.banner_content}>
                    <h1>Our Products</h1>
                    <p>Discover the best fishery product from the ocean</p>
                </Grid>
            </Grid>
            <Features isXs={isXs} />
            {console.log(data)}
            <div className={classes.products_body}>


                {/*         
{data.allWpProduct.edges.map((img,i) => {
    console.log(i)
    
    return (
        <div className={classes.masonry_item}>
        <img src={img.node.image.sourceUrl} />
        </div>
    )
})}   */}

                {/* {data.allWpProduct.edges.forEach((item,idx) => {
    columns.push(
        <div className="col-md py-3" key={idx}>
                        <div className="card card-body">
                        <a className="btn" href={item.link}>{item.node.name}</a>
                        </div>
                    </div>
    )
    if ((idx+1)%4===0) {columns.push(<div className="w-100" key={null}></div>)}
    })}

    <div>{columns}</div> */}
                {/* <img src={data.allWpProduct.edges[0].node.image.sourceUrl} /> */}
                <div className="grid-body">
                    {isTab ? (<div>
                        <Grid container spacing={5}>
                            {data.allWpProduct.edges.map((tab) => {

                                return (
                                    <Grid item xs={12} sm={6} key={tab.node?.id} >
                                        {/* <div className={classes.tab_image}>
            <img src={tab.node?.image?.sourceUrl} alt={tab.node?.name}/>
            <div className={classes.hover_items}>   
            <p className={classes.tab_title}>{tab.node?.name}</p>
            <Link to={`/product/${tab.node?.slug}`}><button className={classes.view_product}>Learn more</button></Link>
            </div>
            </div> */}
                                        <Link to={`/product/${tab.node?.slug}`}>
                                            <div className={classes.feature_image}>
                                                <img src={tab.node?.image?.sourceUrl} alt={tab.node?.name} />
                                            </div>
                                            <p className={classes.feature_title}>{tab.node?.name}</p>
                                            <p className={classes.feature_cat}>{tab.node?.productCategories?.nodes.map(cat => cat.name)}</p>
                                        </Link>


                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>) : (<div>{content}</div>)}

                </div>

                <Grid container className={classes.featured_product}>
                    <Grid xs={12} className={classes.featured_product_content}>
                        <h3>Featured Products</h3>
                    </Grid>
                </Grid>
                <FeatureProduct />

                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify={isSmall ? "center" : "start"}
                    alignItems="center"
                    className={classes.contact_section}

                    style={{ paddingTop: "50px" }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={10}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.map_image}
                    >
                        {/* <img
                            src={MapBox}
                            alt="ocean fishry address"
                            className={classes.map_box}
                        /> */}
                        <img src={MapImage} alt="ocean fishry map" className={classes.map} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={10}
                        md={6}
                        lg={5}
                        xl={5}
                        className={classes.contact_form}

                    >

                        <ContactUs isSmall={isSmall} />
                    </Grid>
                    <div class="ocean">
                        <div class="wave wave1"></div>
                        <div class="wave wave2"></div>
                        <div class="wave wave3"></div>
                        <div class="wave wave4"></div>
                    </div>
                </Grid>
            </div>




        </Layout>
    )
}
export default Products;

export const productQuery = graphql`
query productQuery {
  allWpProduct(
    filter: {productTypes: {nodes: {elemMatch: {name: {eq: "simple"}}}}}
  ) {
    edges {
      node {
        name
        sku
        slug
        shortDescription
        image {
          sourceUrl
        }
        id
        productCustomField {
            scientificName
        }
        featured  
        productCategories {
            nodes {
              name
            }
        }
      }
    }
  }
}


`