import React from 'react';
import {Grid} from '@material-ui/core';
import { useStyles } from '../theme/theme';
import {useStaticQuery, graphql, Link} from 'gatsby';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SwiperCore, {
  Pagination,
Autoplay} from 'swiper/core';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import './Slider/Slider.css';



SwiperCore.use([Pagination,Autoplay]);
export default function FeatureProduct(){
    const productData = useStaticQuery(graphql`
    query FeaturedProduct {
        allWpProduct(filter: {featured: {eq: true}}) {
            edges {
              node {
                featured
                id
                name
                productCategories {
                  nodes {
                    name
                  }
                }
                image {
                  sourceUrl
                }
                slug
              }
            }
          }
    }
    
    
    `);
    const classes = useStyles();
    console.log(productData);
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(768));
    const isMob = useMediaQuery(theme.breakpoints.down(650));
    const is1366 = useMediaQuery(theme.breakpoints.down(1350));

    return (
        <div className={isTablet ? classes.featured_product_detail_tab : classes.featured_product_detail}>
          

<div>
            <Swiper slidesPerView={isMob ? 1 : isTablet ? 2 : is1366 ? 3 : 4} spaceBetween={30} centeredSlides={false} loop={true} loopedSlides={4} autoplay={{"delay":2000}} className="mySwiper">
        {productData.allWpProduct.edges.map(featured => (
          <SwiperSlide className="swiper-slide2" key={featured.node.id}><Link to={`/product/${featured.node?.slug}`}><img  src={featured.node?.image.sourceUrl}/><h4 className={classes.producttitle}>{featured.node?.name}</h4></Link></SwiperSlide>
        ))}
  </Swiper>
          </div>
          
            
        </div>
    )
}